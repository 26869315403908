import 'es6-object-assign/auto'

if (window.MediaSource) {
  // there is issue with videojs and hls when sending to endOfStream undefined/null argument in IE
  const endOfStream = MediaSource.prototype.endOfStream

  window.MediaSource.prototype.endOfStream = function (...args) {
    return args[0] ? endOfStream.apply(this, args) : endOfStream.apply(this)
  }
}

if (document.head.append === undefined) {
  document.head.append = function (args) {
    document.getElementsByTagName('head')[0].appendChild(args)
  }
}

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach
}
